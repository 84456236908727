import React, { Component } from "react";
import {
	Form,
	FormGroup,
	Button,
	ButtonToolbar,
	Col,
	Row,
	Label
} from "reactstrap";
import Select from "react-select";
import alasql from "alasql";
import i18next from "i18next";
import FontAwesome from "react-fontawesome";

class BondSearchFilter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			references: [],
			versions: [],
			owners: [],
			creators: [],
			hasFile: "",

			referenceOptions: [],
			versionOptions: [],
			creatorOptions: [],
			ownerOptions: [],
			flagOptions: []
		};
	}

	resetState = () => {
		this.setState({
			references: [],
			versions: [],
			owners: [],
			creators: [],
			hasFile: ""
		});

		this.props.clear();
	};

	handleSubmit = event => {

console.log(event);

		event.preventDefault();

		let filter = {
			reference: this.state.references.map(reference => reference.value),
			version: this.state.versions.map(version => version.value),
			owner: this.state.owners.map(owner => owner.value),
			creator: this.state.creators.map(creator => creator.value),
			hasFile: this.state.hasFile
				? this.state.hasFile.value
				: undefined,
		};
		this.props.accept(filter);
	};

	loadSelectOptions = () => {
		let referenceOptions = alasql(
			"SELECT DISTINCT reference AS [value], reference AS label FROM bonds"
		);
		let versionOptions = alasql(
			"SELECT DISTINCT version AS [value], version AS label FROM bonds"
		);

		let creatorOptions = alasql(
			"SELECT DISTINCT creator AS [value], creatorName AS label FROM bonds"
		);
		let ownerOptions = alasql(
			"SELECT DISTINCT owner AS [value], ownerName AS label FROM bonds"
		);

		let flagOptions = [
			{ value: true, label: i18next.t("option.yes") },
			{ value: false, label: i18next.t("option.no") },
			{ value: undefined, label: i18next.t("option.all") }
		];

		this.setState({
			referenceOptions: referenceOptions,
			versionOptions: versionOptions,
			creatorOptions: creatorOptions,
			ownerOptions: ownerOptions,
			flagOptions: flagOptions
		});
	};

	componentWillMount = () => {
		this.loadSelectOptions();
	};

	render() {
		return (
			<Row>
				<Col className="ml-3 left-form">
					<Form >
						<FormGroup row>
							<Label
								for="bondReference"
								sm={2}
								className="text-right"
							>
								{i18next.t("form.bond.reference")}
							</Label>
							<Col sm={10}>
								<Select
									id="bondReference"
									value={this.state.references}
									isMulti={true}
									required={false}
									options={this.state.referenceOptions}
									onChange={value =>
										this.setState({ references: value })
									}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label
								for="bondCreator"
								sm={2}
								className="text-right"
							>
								{i18next.t("form.bond.creator")}
							</Label>
							<Col sm={10}>
								<Select
									id="bondCreator"
									name="form-field-name"
									value={this.state.creators}
									isMulti={true}
									required={false}
									options={this.state.creatorOptions}
									onChange={value =>
										this.setState({ creators: value })
									}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label
								for="bondOwner"
								sm={2}
								className="text-right"
							>
								{i18next.t("form.bond.owner")}
							</Label>
							<Col sm={10}>
								<Select
									id="bondOwner"
									name="form-field-name"
									value={this.state.owners}
									isMulti={true}
									required={false}
									options={this.state.ownerOptions}
									onChange={value =>
										this.setState({ owners: value })
									}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label
								for="bondFile"
								sm={2}
								className="text-right"
							>
								{i18next.t("form.bond.file")}
							</Label>
							<Col sm={10}>
								<Select
									id="bondFile"
									name="form-field-name"
									value={this.state.hasFile}
									required={false}
									options={this.state.flagOptions}
									onChange={value =>
										this.setState({
											hasFile: value 
										})
									}
								/>
							</Col>
						</FormGroup>
						<ButtonToolbar className="float-right">
							<Button className="btn-default" type="submit" onClick={this.handleSubmit}>
								<FontAwesome
									name="search"
									className="align-middle text-info"
								/>
								<Label className="mb-auto align-middle ml-2">
									{i18next.t("button.search.accept")}
								</Label>
							</Button>
							<Button
								className="btn-default"
								onClick={this.resetState}
							>
								<FontAwesome
									name="search-minus"
									className="align-middle text-info"
								/>
								<Label className="mb-auto align-middle ml-2">
									{i18next.t("button.search.clear")}
								</Label>
							</Button>
						</ButtonToolbar>
					</Form>
				</Col>
			</Row>
		);
	}
}

export default BondSearchFilter;
