import React, { Component } from "react";
import { Table, Button, ButtonToolbar, Label, Alert } from "reactstrap";
import Bond from "./Bond.jsx";
import BondView from "./BondView.jsx";
import BondForm from "./BondForm.jsx";
import BondDetails from "./BondDetails.jsx";
import BondHistory from "./BondHistory.jsx";
import BondSearchFilter from "./BondSearchFilter.jsx";
import IdentityForm from "./IdentityForm.jsx";
import alasql from "alasql";
import i18next from "i18next";
import FileSaver from "file-saver";
import { base64StringToBlob } from "blob-util";
import underscoreString from "underscore.string";
import FontAwesome from "react-fontawesome";
import PaginationComponent from "react-reactstrap-pagination";

class BondList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			count: 0,
			pageNr: 1,
			pageOffset: 0,
			pageSize: 10,
			pageCount: 1,
			pagedBonds: [],
			showDetails: false,
			showSearchFilter: false,
			bondForDetails: null,
			bond: null,
			approve: false,
			rejec6: false,
			refresh: false,
			filter: {},
			showError: false,
			error: null,
			showBond: false,
			bondFile: null,
			showCreateBond: true,
			showCreateIdentity: true,
			showBondForm: false,
			showIdentityForm: false,
			QA: true
		};
	}

	handlePageSelect = pageNr => {
		this.setState({
			pageNr: pageNr,
			pageOffset: this.state.pageSize * (pageNr - 1)
		}, this.selectBonds);
	};

	timerComponent = () => {};

	showDetails = bond => {
		this.setState({
			showDetails: true,
			bondForDetails: bond
		});
	};

	hideDetails = () => {
		this.setState({
			showDetails: false,
			bondForDetails: null
		});
	};

	showHistory = bond => {
		this.setState({
			showHistory: true,
			bond: bond
		});
	};

	hideHistory = () => {
		this.setState({
			showHistory: false,
			bond: null
		});
	};

	showBondForm = () => {
		this.setState({
			showBondForm: true
		});
	};

	hideBondForm = () => {
		this.setState({
			showBondForm: false
		});
	};

	showIdentityForm = () => {
		this.setState({
			showIdentityForm: true
		});
	};

	hideIdentityForm = () => {
		this.setState({
			showIdentityForm: false
		});
	};

	showSearchFilter = bond => {
		this.setState({
			showSearchFilter: true
		});
	};

	acceptSearchFilter = filter => {
		console.log("accept filter");
		console.log(filter);
		console.log("accepted filter");
		// referesh the list after applying the filter in the state ...
		this.setState(
			{
				filter: filter
			},
			() => {
				this.selectBonds();
			}
		);
	};

	clearSearchFilter = () => {
		this.setState(
			{
				filter: {}
			},
			() => {
				this.selectBonds();
			}
		);
	};

	hideSearchFilter = () => {
		this.setState({
			showSearchFilter: false
		});
	};

	delayPromise = duration => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				console.log("resolve timeout");
				resolve(undefined);
			}, duration);
		});
	};

	selectBonds = () => {
		console.log("select bols");
		console.log(this.state.filter);
		// generate the WHERE clauses from the filter

		let defaultWhere =
			"";

		let whereClause = "";
		let queryParameters = [];

		Object.entries(this.state.filter).forEach(
			([filterField, filterValue]) => {
				if (filterValue != null) {
					if (Array.isArray(filterValue)) {
						if (filterValue.length > 0) {
							whereClause.length === 0
								? (whereClause = `WHERE bol.${filterField} IN (`)
								: (whereClause = `${whereClause} AND bol.${filterField} IN (`);

							filterValue.forEach(value => {
								whereClause = `${whereClause}?,`;
								queryParameters.push(value);
							});
							whereClause = whereClause.substring(
								0,
								whereClause.length - 1
							);
							whereClause = `${whereClause})`;
						}
					} else {
						if (filterField.startsWith("count")) {
							let tmpField = filterField.replace("count", "");
							tmpField = underscoreString.decapitalize(tmpField);
							whereClause.length === 0
								? (whereClause = `WHERE bol.${tmpField}.length ${
										filterValue ? " > 0 " : " = 0 "
								  }`)
								: (whereClause = `${whereClause} AND bol.${tmpField}.length ${
										filterValue ? " > 0 " : " = 0 "
								  }`);
						} else if (filterField.startsWith("has")) {
							let tmpField = filterField.replace("has", "");
							tmpField = underscoreString.decapitalize(tmpField);
							whereClause.length === 0
								? (whereClause = `WHERE bol.${tmpField} ${
										filterValue === true
											? " IS NOT NULL "
											: " IS NULL "
								  }`)
								: (whereClause = `${whereClause} AND bol.${tmpField} ${
										filterValue === true
											? " IS NOT NULL "
											: " IS NULL "
								  }`);
						} else if (filterField.startsWith("is")) {
							let tmpField = filterField.replace("is", "");
							tmpField = underscoreString.decapitalize(tmpField);
							whereClause.length === 0
								? (whereClause = `WHERE bol.${tmpField} = ?`)
								: (whereClause = `${whereClause} AND bol.${tmpField} = ?`);
							queryParameters.push(filterValue);
						} else {
							whereClause.length === 0
								? (whereClause = `WHERE bol.${filterField} = ?`)
								: (whereClause = `${whereClause} AND bol.${filterField} = ?`);
							queryParameters.push(filterValue);
						}
					}
				}
			}
		);

		console.log(whereClause);
		console.log(queryParameters);

		// performat the select with OFFSET at current page and LIMIT at next page
		let bondsCount = alasql(
			`SELECT VALUE count(*) 
			FROM bonds AS bol 
			LEFT JOIN organisations AS creatorOrg ON bol.creatorId = creatorOrg.id
			LEFT JOIN organisations AS ownerOrg ON bol.ownerId = ownerOrg.id ${whereClause}`,
			queryParameters
		);

		if (!whereClause) {
			whereClause = defaultWhere;
		}

		let bondsQuery = `SELECT 
			bol.*
			FROM bonds AS bol 
			LEFT JOIN organisations AS creatorOrg ON bol.creatorId = creatorOrg.id
			LEFT JOIN organisations AS ownerOrg ON bol.ownerId = ownerOrg.id ${whereClause} 
			ORDER BY reference LIMIT ${this.state.pageSize} OFFSET ${
			this.state.pageOffset
		}`;

		console.log(bondsQuery);

		let bonds = alasql(bondsQuery, queryParameters);

		console.log(bonds);

		// set the result in the state so it can displayed
		this.setState({
			count: bondsCount,
			pageCount: Math.floor(bondsCount / this.state.pageSize + 1),
			pagedBonds: bonds
		});
	};

	timer = () => {
		this.props
			.fetch()
			.then(() => {
				this.selectBonds();
			})
			.catch(error => {
				throw error;
			});
	};

	downloadFile = (id) => {
		this.props.services.bondService
			.get(id, { query: { type: "file" } })
			.then(file => {
				console.log(file.name);
				console.log(file.content);

				let blob = base64StringToBlob(
					file.content,
					"application/octet-stream;charset=UTF-8"
				);
				FileSaver.saveAs(blob, file.name);
			})
			.catch(error => {
				this.handleError(error);
			});
	};

	componentWillMount() {
		let interval = setInterval(this.timer, 60000);

		const handleEvent = refresh => {
			this.props.services.bondService
				.get(refresh.id, { query: {} })
				.then(bond => {
					alasql("DELETE FROM bonds WHERE id = ?", [
						refresh.id
					]);
					alasql("SELECT * INTO bonds FROM ?", [[bond]]);
					this.selectBonds();
				})
				.catch(error => {
					this.handleError(error);
				});
		};

		// seth the refresh handler
		this.props.services.bondService.on("refresh", handleEvent);

		this.setState({
			interval: interval,
			eventHandler: handleEvent,
			QA: this.props.app.user.organizationId === 0
		});

		// fetch the data on init/mount
		this.props
			.fetch()
			.then(() => {
				// select the data for display
				this.selectBonds();
			})
			.catch(error => {
				this.handleError(error);
			});
	}

	handleError = error => {
		let errorMessage = "";
		if (error && error.response && error.response.text) {
			try {
				let body = JSON.parse(error.response.text);
				console.log(body);
				errorMessage = body.error;
			} catch (error) {
				errorMessage = error.response;
			}
		} else {
			errorMessage = error.message;
		}

		console.log(errorMessage);

		this.setState({
			showError: true,
			error: errorMessage
		});
	};

	hideError = () => {
		this.setState({
			showError: false,
			error: null
		});
	};

	showBond = (id) => {
		this.props.services.bondService
			.get(id, { query: { type: "file" } })
			.then(file => {
				let buffer = Buffer.from(file.content, "base64");
				// convert the buffer to an Uint8Array as expected by the pdf viewer
				let bb = buffer.slice(
					buffer.byteOffset,
					buffer.byteOffset + buffer.byteLength
				);

				this.setState({ showBond: true, bondFile: bb });
			})
			.catch(error => {
				this.handleError(error);
			})
	}

	hideBond = () => {
		this.setState({showBond: false})
	}

	componentWillUnmount() {
		console.log("unmounting bond list");
		clearInterval(this.state.interval);
		// remove the refresh handler
		this.props.services.bondService.removeListener(
			"refresh",
			this.state.eventHandler
		);
	}

	render() {
		return (
			<div>
				<ButtonToolbar>
					{!this.state.showSearchFilter && (
						<Button
							className="btn-default"
							onClick={() => this.showSearchFilter()}
						>
							<FontAwesome
								name="eye"
								className="align-middle text-info"
							/>
							<Label className="mb-auto align-middle ml-2">
								{i18next.t("button.search.show")}
							</Label>
						</Button>
					)}
					{this.state.showSearchFilter && (
						<Button
							className="btn-default"
							onClick={() => this.hideSearchFilter()}
						>
							<FontAwesome
								name="eye-slash"
								className="align-middle text-info"
							/>
							<Label className="mb-auto align-middle ml-2">
								{i18next.t("button.search.hide")}
							</Label>
						</Button>
					)}
					{this.state.showCreateBond && this.state.QA && (
						<Button
							className="btn-default"
							onClick={() => this.showBondForm()}
						>
							<FontAwesome
								name="plus"
								className="align-middle text-info"
							/>
							<Label className="mb-auto align-middle ml-2">
								{i18next.t("button.bondform.show")}
							</Label>
						</Button>
					)}
					{this.state.showCreateIdentity && this.state.QA && (
						<Button
							className="btn-default"
							onClick={() => this.showIdentityForm()}
						>
							<FontAwesome
								name="user-plus"
								className="align-middle text-info"
							/>
							<Label className="mb-auto align-middle ml-2">
								{i18next.t("button.identityform.show")}
							</Label>
						</Button>
					)}
				</ButtonToolbar>

				{this.state.showBondForm &&
					<BondForm
						services={this.props.services}
						show={this.state.showBondForm}
						hide={this.hideBondForm}
					/>
				}

				{this.state.showIdentityForm &&
					<IdentityForm
						services={this.props.services}
						show={this.state.showIdentityForm}
						hide={this.hideIdentityForm}
					/>
				}

				{this.state.showBond &&
					<BondView
						show={this.state.showBond}
						hide={this.hideBond}
						file={this.state.bondFile}
					/>
				}

				{this.state.showError && (
					<Alert
						color="danger"
						isOpen={this.state.showError}
						toggle={this.hideError}
					>
						<Label>{this.state.error}</Label>
					</Alert>
				)}

				{this.state.showSearchFilter && (
					<BondSearchFilter
						accept={this.acceptSearchFilter}
						clear={this.clearSearchFilter}
						filter={this.state.filter}
					/>
				)}
				{this.state.showDetails && (
					<BondDetails
						bond={this.state.bondForDetails}
						hide={this.hideDetails}
						show={this.state.showDetails}
						services={this.props.services}
					/>
				)}
				{this.state.showHistory && (
					<BondHistory
						bond={this.state.bond}
						hide={this.hideHistory}
						services={this.props.services}
					/>
				)}
				<Table responsive={true} striped={true}>
					<thead>
						<tr>
							<th>{i18next.t("table.header.reference")}</th>
							<th>{i18next.t("table.header.creator")}</th>
							<th>{i18next.t("table.header.created")}</th>
							<th>{i18next.t("table.header.owner")}</th>
							<th>{i18next.t("table.header.file")}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{this.state.pagedBonds.map(bond => (
							<Bond
								key={`${bond.id}`}
								app={this.props.app}
								bond={bond}
								services={this.props.services}
								showDetails={this.showDetails}
								showHistory={this.showHistory}
								showBond={this.showBond}
								download={this.downloadFile}
								QA={this.state.QA}
							/>
						))}
					</tbody>
				</Table>
				{this.state.pageCount > 1 && (
					<div className="text-center">
						<PaginationComponent
							totalItems={this.state.count}
							pageSize={this.state.pageSize}
							onSelect={this.handlePageSelect}
							activePage={this.state.pageNr}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default BondList;
