import React, { Component } from "react";
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Label
} from "reactstrap";
import FontAwesome from "react-fontawesome";

import i18next from "i18next";

class BondActionButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dropdownOpen: false
		};
	}

	toggle = () => {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	};

	render() {
		let bond = this.props.bond;

		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
				<DropdownToggle caret className="btn-default">
					<FontAwesome name="cog" className="text-info" />
				</DropdownToggle>
				<DropdownMenu right>
					{1 == 0 && (
						<DropdownItem
							onClick={event => this.props.showHistory(bond)}
						>
							<FontAwesome name="history" className="text-info" />
							<Label className="mb-auto align-middle ml-2">
								{i18next.t("button.history")}
							</Label>
						</DropdownItem>
					)}
					<DropdownItem
						onClick={event => this.props.showDetails(bond)}
					>
						<FontAwesome name="info" className="text-info mr-2" />
						<Label className="mb-auto align-middle ml-2">
							{i18next.t("button.details")}
						</Label>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
}

export default BondActionButton;
