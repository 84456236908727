import React, { Component } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	Button,
	ButtonToolbar,
	Label,
	Table
} from "reactstrap";
import DateTime from "./DateTime.jsx";
import i18next from "i18next";
import moment from "moment";
import FontAwesome from "react-fontawesome";
import alasql from "alasql";
import PaginationComponent from "react-reactstrap-pagination";

class BondHistory extends Component {
	constructor(props) {
		super(props);

		this.state = {
			history: [],
			pageSize: 10,
			activePage: 1
		};
	}

	handlePageSelect = (pageNr) => {
		this.setState({
			activePage: pageNr
		})
	}

	componentWillMount() {
		this.props.services.bondService
			.get(this.props.bond.id, { query: { type: "history" } })
			.then(history => {
console.log(history);				
				this.setState({ history: history.filter(element => element.what !== "Share") });
			})
			.catch(error => {
				console.log(error);
			});
	}

	render() {
		let count = 0;
		let nbrPages = 0;
		let logs = [];

		if (this.state.history) {
			count = this.state.history.length;

			nbrPages =
				Math.floor(count / this.state.pageSize) +
				(count % this.state.pageSize > 0 ? 1 : 0);

			let offset = (this.state.activePage - 1) * this.state.pageSize;
			let remainder = this.state.history.length - offset;

			console.log(
				this.state.activePage +
					" - " +
					offset +
					" --- " +
					remainder +
					" ---- " +
					this.state.history.length
			);

			if (remainder <= this.state.pageSize) {
				logs = this.state.history.slice(offset);
			} else {
				logs = this.state.history.slice(
					offset,
					offset + this.state.pageSize
				);
			}

			console.log(logs);
		}

		return (
			<Modal isOpen={true}>
				<ModalHeader>
					{i18next.t("title.bond.history")}
				</ModalHeader>

				<ModalBody>
					{this.state.history && (
						<Table responsive={true} striped={true}>
							<thead>
								<tr>
									<th>{i18next.t("table.header.sender")}</th>
									<th>{i18next.t("table.header.receiver")}</th>
									<th>{i18next.t("table.header.event")}</th>
									<th>
										{i18next.t("table.header.timestamp")}
									</th>
								</tr>
							</thead>
							<tbody>
								{this.state.history.length > 0 &&
									logs.map(log => (
										<tr key={JSON.stringify(log)}>
											<td>
												{alasql(
													"SELECT VALUE name FROM organisations WHERE id = ?",
													[log.senderId]
												)}
											</td>
											<td>
												{alasql(
													"SELECT VALUE name FROM organisations WHERE id = ?",
													[log.receiverId]
												)}
											</td>

											<td>
												<label>{log.what}</label>
											</td>

											<td>
												{log.when && (
													<DateTime
														dateTime={moment(
															log.when.timestamp
														)}
													/>
												)}
											</td>
										</tr>
									))}
								{this.state.history.length === 0 && (
									<Label className="mb-auto align-middle ml-2">
										{i18next.t(
											"label.bond.no_history"
										)}
									</Label>
								)}
							</tbody>
						</Table>
					)}

					{nbrPages > 1 && (
						<div className="text-center">
							<PaginationComponent
								totalItems={count}
								pageSize={this.state.pageSize}
								onSelect={this.handlePageSelect}
							/>
						</div>
					)}

					<ButtonToolbar className="float-right">
						<Button
							className="btn-default"
							onClick={this.props.hide}
						>
							<FontAwesome
								name="times"
								className="text-success"
							/>
						</Button>
					</ButtonToolbar>
				</ModalBody>
			</Modal>
		);
	}
}

export default BondHistory;
