import React, { Component } from "react";
import moment from "moment";

class DateTime extends Component {
	render() {
		let dateTime =
			this.props.dateTime != null
				? moment(this.props.dateTime).format("DD/MM/YYYY HH:mm")
				: null;

		return <div>{dateTime}</div>;
	}
}

export default DateTime;