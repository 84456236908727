import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
	Form,
	FormGroup,
	Label,
	Input,
	Col,
	Button,
	ButtonToolbar,
	Modal,
	ModalHeader,
	ModalBody
} from "reactstrap";
import i18next from "i18next";
import FontAwesome from "react-fontawesome";

class WalletConfig extends Component {
	constructor(props) {
		super(props);

		this.state = { url: "", token: "" };
	}

	handleSubmit = event => {

		if (!event.target.reportValidity()) {
			return;
		}

		event.preventDefault();

		try {
	    	window.localStorage.setItem("tmining-wallet-url", this.state.url);	
		} catch (error) {
			console.log(error);
		}
		try {
	    	window.localStorage.setItem("tmining-wallet-token", this.state.token);	
		} catch (error) {
			console.log(error);
		}

		this.props.hide();
	};

	componentWillMount() {
		try {
	    	let url = window.localStorage.getItem("tmining-wallet-url");
			this.setState({url : url == null ? undefined : url});
		} catch (error) {

		}
		try {
	    	let token = window.localStorage.getItem("tmining-wallet-token");
			this.setState({token : token == null ? undefined : token});
		} catch (error) {

		}
	}

	render() {
		return (
			<Modal isOpen={this.props.show} className="modal-width2" >
				<ModalHeader>{i18next.t("title.walletconfig")}</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit} ref={el => this.form = el}>
						<FormGroup row>
							<Label for="url" sm={2} className="text-right">
								{i18next.t("form.walleturl")}
							</Label>
							<Col sm={10}>
									<Input
										id="url"
										type="url"
										placeholder="Enter wallet url"
										value={this.state.url}
										required={true}
										onChange={e =>
											this.setState({
												url: e.target.value
											})
										}
									/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label for="url" sm={2} className="text-right">
								{i18next.t("form.wallettoken")}
							</Label>
							<Col sm={10}>
									<Input
										rows={10}
										id="token"
										type="textarea"
										placeholder="Enter wallet jwt token"
										value={this.state.token}
										required={true}
										onChange={e =>
											this.setState({
												token: e.target.value
											})
										}
									/>
							</Col>
						</FormGroup>

						<ButtonToolbar className="float-right">
								<Button
									className="btn-default"
									type="button"
									onClick={() => {ReactDOM.findDOMNode(this.form).dispatchEvent(new Event("submit"));}}
								>
									<FontAwesome
										name="check"
										className="text-success"
									/>
									<Label className="mb-auto align-middle ml-2">
										{i18next.t("button.accept")}
									</Label>
								</Button>
							<Button
								className="btn-default"
								type="button"
								onClick={this.props.hide}
							>
								<FontAwesome
									name="times"
									className="text-danger"
								/>
								<Label className="mb-auto align-middle ml-2">
									{i18next.t("button.cancel")}
								</Label>
							</Button>
						</ButtonToolbar>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

export default WalletConfig;
