import React, { Component } from "react";
import {
	Table,
	Button,
	ButtonToolbar,
	Label,
	UncontrolledTooltip
} from "reactstrap";
import BondView from "./BondView.jsx";
import DateTime from "./DateTime.jsx";
import i18next from "i18next";
import FontAwesome from "react-fontawesome";
import { blobToBase64String } from "blob-util";
import { SHA3 } from 'sha3';
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
	pdfjs.version
}/pdf.worker.js`;

/**
 * Represents a base bond
 * @class
 */
class BondDetailsPage extends Component {
	/**
	 * @constructs
	 */
	constructor(props) {
		super(props);

		this.state = {
			uploadVerified: false,
			hashVerifiedOK: false,
			showFile: false,
			file: null,
			numPages: null,
			pageNumber: 1,
			documentLoaded: false
		};
	}

	onDocumentLoadSuccess = event => {
		let numPages = event._pdfInfo.numPages;
		this.setState({ numPages: numPages, documentLoaded: true, pdf: event });
	};

	handlePageSelect = pageNr => {
		this.setState({
			pageNumber: pageNr
		});
	};

	showFile = () => {
		this.setState({ showFile: true });
	};

	uploadFile = event => {
		let id = this.props.bond.id;
		let uploadedFile = event.target.files[0];

		blobToBase64String(uploadedFile)
			.then(base64File => {
				// calculate the md5 file hash of the base64 encoding
				let hash = SHA3(256);
				let fileHash = hash
					.update(base64File)
					.digest("hex");
				console.log(fileHash);

				this.props.services.bondVerificationService
					.get(id, { query: { type: "verifyHash", hash: fileHash, key: this.props.reference } })
					.then(result => {
						if (result.verified) {
							this.setState({uploadVerified: true, hashVerifiedOK: true});
						} else {
							this.setState({uploadVerified: true, hashVerifiedOK: false});
						}					
					})
					.catch(error => {
						this.handleError(error);
					});
			})
			.catch(error => {
				this.handleError(error);
			});
	};

	renderDownloadComponent = file => {

		let tooltip = `View bond`;

		return (
			<div className="text-center">
				{file && (
					<Button
						id={`DownloadComponent_${this.props.bond.id}`}
						className={"btn-link padding-sides-0"}
						onClick={() => {
							this.showFile(this.props.bond.id);
						}}
					>
						<FontAwesome
							name="eye"
							size="2x"
							className="text-success"
						/>
						<Label className="mb-auto align-middle ml-2">
							{i18next.t("label.bond.show")}
						</Label>
					</Button>
				)}
				{file && (
					<UncontrolledTooltip
						target={`DownloadComponent_${this.props.bond.id}`}
					>
						{tooltip}
					</UncontrolledTooltip>
				)}
			</div>
		);
	};

	renderUploadComponent = () => {
		return (
			<div className="text-center">
				<Label
					className="btn btn-link mb-auto"
					id={`UploadDownloadComponent_${this.props.bond.id}`}
				>
					<FontAwesome
						name="user-check"
						size="2x"
						className="text-info"
					/>
					<input
						className={"btn-link"}
						type="file"
						onChange={event => this.uploadFile(event)}
						style={{ display: "none" }}
						id="group_image"
					/>
					<Label className="mb-auto align-middle ml-2">
						{i18next.t("label.bond.verify")}
					</Label>
				</Label>
			</div>
		);
	};

	handleError = error => {
		console.log(error);
	};

	/**
	 * @override
	 */
	componentWillReceiveProps(props) {}

	componentWillMount() {
		(async() => {
			let buffer = Buffer.from(this.props.bond.file.content, "base64");
			// convert the buffer to an Uint8Array as expected by the pdf viewer
			let bb = buffer.slice(
				buffer.byteOffset,
				buffer.byteOffset + buffer.byteLength
			);

			this.buffer = bb;
		})().then(() => {
console.log("done");
		}).catch(error => {
console.log(error);
		})
	}

	/**
	 *
	 */
	render() {
		console.log(this.state);

		return (
			<div id="verification-page" class="text-center">
			<div class="d-inline-block">
				<Table id="detailsTable" striped>
					<tbody>
						<tr>
							<td style={{ width: "16.66%" }}>
								{i18next.t("table.header.reference")}
							</td>
							<td>{this.props.bond.reference}</td>
						</tr>
						<tr>
							<td style={{ width: "16.66%" }}>
								{i18next.t("table.header.address")}
							</td>
							<td>{this.props.bond.address}</td>
						</tr>
						<tr>
							<td style={{ width: "16.66%" }}>
								{i18next.t("table.header.creator")}
							</td>
							<td>{this.props.bond.creatorName}  ({this.props.bond.creator})</td>
						</tr>
						<tr>
							<td style={{ width: "16.66%" }}>
								{i18next.t("table.header.created")}
							</td>
							<td>
								<DateTime
									dateTime={this.props.bond.creationTime}
								/>
							</td>
						</tr>
						<tr>
							<td>{i18next.t("table.header.owner")}</td>
							<td>{this.props.bond.ownerName}  ({this.props.bond.owner})</td>
						</tr>
						<tr>
							<td>{i18next.t("table.header.verified")}</td>
							<td>
								{!this.state.uploadVerified && (
									<FontAwesome
										name="question-circle"
										size="2x"
										className="text-info"
									/>
								)}
								{this.state.uploadVerified &&
									this.state.hashVerifiedOK && (
										<FontAwesome
											name="check-circle"
											size="2x"
											className="text-success"
										/>
									)}
								{this.state.uploadVerified &&
									!this.state.hashVerifiedOK && (
										<FontAwesome
											name="times-circle"
											size="2x"
											className="text-danger"
										/>
									)}
							</td>
						</tr>
						<tr>
							<td>
								{i18next.t("table.header.fileHash")}
							</td>
							<td>
								{this.props.bond.fileHash}
							</td>
						</tr>
					</tbody>
				</Table>
</div>
				<ButtonToolbar>
					{this.renderDownloadComponent(this.props.bond.fileHash)}

					{this.renderUploadComponent()}
				</ButtonToolbar>

				{this.state.showFile &&
					<BondView
						show={this.state.showFile}
						hide={() => {this.setState({showFile: false})}}
						file={this.buffer}
					/>
				}

			</div>
		);
	}
}

export default BondDetailsPage;
