import React, { Component } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	Table,
} from "reactstrap";
import DateTime from "./DateTime.jsx";
import i18next from "i18next";

/**
 * Represents a base bond
 * @class
 */
class BondDetails extends Component {
	/**
	 * @constructs
	 */
	constructor(props) {
		super(props);

		this.state = {
			instructionsName: "",
		};
	}

	/**
	 * @override
	 */
	componentWillReceiveProps(props) {}

	/**
	 *
	 */
	render() {
		let bond = this.props.bond;

		let fileId = bond.file === undefined ? 0 : parseInt(bond.file, 16);

		return (
			<Modal isOpen={this.props.show} toggle={this.props.hide} className="modal-width2">
				<ModalHeader toggle={this.props.hide}>{i18next.t("title.details")}</ModalHeader>
				<ModalBody>
					<Table id="detailsTable" striped>
						<tbody>
							<tr>
								<td>{i18next.t("table.header.reference")}</td>
								<td>{this.props.bond.reference}</td>
							</tr>
							<tr>
								<td>{i18next.t("table.header.address")}</td>
								<td>{this.props.bond.address}</td>
							</tr>
							<tr>
								<td>{i18next.t("table.header.creator")}</td>
								<td>{this.props.bond.creatorName}  ({this.props.bond.creator})</td>
							</tr>
							<tr>
								<td>{i18next.t("table.header.created")}</td>
								<td>
									<DateTime
										dateTime={
											this.props.bond.creationTime
										}
									/>
								</td>
							</tr>
							<tr>
								<td>{i18next.t("table.header.owner")}</td>
								<td>{this.props.bond.ownerName}  ({this.props.bond.owner})</td>
							</tr>
							<tr>
								<td>
									{i18next.t("table.header.fileHash")}
								</td>
								<td>
									{fileId === 0 ? i18next.t("label.na") : this.props.bond.file}
								</td>
							</tr>
						</tbody>
					</Table>
				</ModalBody>
			</Modal>
		);
	}
}

export default BondDetails;
