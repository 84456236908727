import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
	Form,
	FormGroup,
	Label,
	Input,
	Col,
	Button,
	ButtonToolbar,
	Modal,
	ModalHeader,
	ModalBody
} from "reactstrap";
import i18next from "i18next";
import FontAwesome from "react-fontawesome";
import { blobToBase64String } from "blob-util";

class BondForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			reference: "",
			address: "",
			clientPublicKey: "",
			file: { name: "", content: "" }
		};
	}

	handleSubmit = event => {
		if (!event.target.reportValidity()) {
			return;
		}

		event.preventDefault();

		let bond = {
			reference: this.state.reference,
			owner: this.state.address,
			clientPublicKey: this.state.clientPublicKey
		};

		if (this.state.file.name && this.state.file.content) {
			bond.file = this.state.file;
		}

		this.props.services.bondService
			.create(bond, { query: {} })
			.then(result => {
				this.props.hide();
			})
			.catch(error => {
				console.log(error);
				// add error handler here
				this.props.hide();
			});
	};

	fetchAdressAndKey = () => {

		let url = window.localStorage.getItem("tmining-wallet-url");
		let token = window.localStorage.getItem("tmining-wallet-token");
		this.props.services.walletService
			.getAddressAndKeyForReference(token, this.state.reference, url)
			.then(rpc => {
				this.setState({
					address: rpc.result.address,
					clientPublicKey: rpc.result.pubKey
				});
			})
			.catch(error => {
				// ??
			});
	};

	uploadFile = event => {
		let uploadedFile = event.target.files[0];

		blobToBase64String(uploadedFile)
			.then(base64File => {
				let file = {
					name: uploadedFile.name,
					content: base64File
				};

				this.setState({ file: file });
			})
			.catch(error => {
				this.handleError(error);
			});
	};

	renderUploadComponent = () => {
		return (
			<div className="text-left">
				<Label
					className="btn btn-link mb-auto"
					id={`UploadDownloadComponent`}
				>
					<FontAwesome
						name="file-upload"
						size="2x"
						className="text-info"
					/>
					<input
						className={"btn-link"}
						type="file"
						onChange={event => this.uploadFile(event)}
						style={{ display: "none" }}
						id="group_image"
					/>
				</Label>
				<Label>{this.state.file.name}</Label>
			</div>
		);
	};

	componentWillMount() {}

	render() {
		return (
			<Modal isOpen={this.props.show} className="modal-width2">
				<ModalHeader>{i18next.t("title.bondform")}</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit} ref={el => this.form = el}>
						<FormGroup row>
							<Label
								for="reference"
								sm={2}
								className="text-right"
							>
								{i18next.t("form.bond.reference")}
							</Label>
							<Col sm={10}>
								<Input
									id="reference"
									type="text"
									placeholder="Enter reference"
									value={this.state.reference}
									required={true}
									onBlur={e => {
										this.fetchAdressAndKey();
									}}
									onChange={e =>
										this.setState({
											reference: e.target.value
										})
									}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label for="address" sm={2} className="text-right">
								{i18next.t("form.bond.address")}
							</Label>
							<Col sm={10}>
								<Input
									id="address"
									type="text"
									placeholder="Enter address"
									value={this.state.address}
									required={true}
									readOnly={true}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label for="key" sm={2} className="text-right">
								{i18next.t("form.bond.key")}
							</Label>
							<Col sm={10}>
								<Input
									id="key"
									type="text"
									placeholder="Enter public key"
									value={this.state.clientPublicKey}
									required={true}
									readOnly={true}
								/>
							</Col>
						</FormGroup>

						<FormGroup row>
							<Label for="client" sm={2} className="text-right">
								{i18next.t("form.bond.file")}
							</Label>
							<Col sm={10}>{this.renderUploadComponent()}</Col>
						</FormGroup>

						<ButtonToolbar className="float-right">
							<Button
								className="btn-default"
								type="button"
								onClick={() => {ReactDOM.findDOMNode(this.form).dispatchEvent(new Event("submit"));}}
							>
								<FontAwesome
									name="check"
									className="text-success"
								/>
								<Label className="mb-auto align-middle ml-2">
									{i18next.t("button.accept")}
								</Label>
							</Button>
							<Button
								className="btn-default"
								type="button"
								onClick={this.props.hide}
							>
								<FontAwesome
									name="times"
									className="text-danger"
								/>
								<Label className="mb-auto align-middle ml-2">
									{i18next.t("button.cancel")}
								</Label>
							</Button>
						</ButtonToolbar>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

export default BondForm;
