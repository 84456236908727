import React, { Component } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
} from "reactstrap";
import i18next from "i18next";
import { Document, Page } from "react-pdf";
import PaginationComponent from "react-reactstrap-pagination";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
	pdfjs.version
}/pdf.worker.js`;

/**
 * Represents a base bond
 * @class
 */
class BondView extends Component {
	/**
	 * @constructs
	 */
	constructor(props) {
		super(props);

		this.state = {
			pdf: "",
			pageNumber: 1,
			numPages: 1,
			documentLoaded: false
		};
	}

	onDocumentLoadSuccess = event => {
		let numPages = event._pdfInfo.numPages;
		this.setState({ numPages: numPages, documentLoaded: true, pdf: event });
	};

	handlePageSelect = pageNr => {
		this.setState({
			pageNumber: pageNr
		});
	};

	render() {

		console.log(this.state);

		return (
				<Modal
					isOpen={this.props.show}
					toggle={this.props.hide}
					className="modal-width"
				>
					<ModalHeader
						toggle={this.props.hide}
					>
						{i18next.t("title.bond.show")}
					</ModalHeader>
					<ModalBody>
					<div className="text-center">
						{!this.state.documentLoaded && (
							<Document
								file={{ data: this.props.file }}
								onLoadSuccess={this.onDocumentLoadSuccess}
							/>
						)}
						{this.state.documentLoaded && (
							<Page
								scale={0.88}
								pageNumber={this.state.pageNumber}
								pdf={this.state.pdf}
								className="react-pdf-display-none"
							/>
						)}
						</div>

						{this.state.numPages > 1 && (
							<div className="text-center pdf-pagination">
								<PaginationComponent
									totalItems={this.state.numPages}
									pageSize={1}
									onSelect={this.handlePageSelect}
									activePage={this.state.pageNumber}
								/>
							</div>
						)}
					</ModalBody>
				</Modal>
		);
	}
}

export default BondView;
