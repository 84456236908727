import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
	Form,
	FormGroup,
	Label,
	Input,
	Col,
	Button,
	ButtonToolbar,
	Modal,
	ModalHeader,
	ModalBody
} from "reactstrap";
import i18next from "i18next";
import FontAwesome from "react-fontawesome";

class IdentityForm extends Component {
	constructor(props) {
		super(props);

		this.state = { address: "", name: "", readonly: false };
	}

	handleSubmit = event => {
		if (!event.target.reportValidity()) {
			return;
		}

		event.preventDefault();
		// call to API with name and address
		this.props.services.identityService
			.create(
				{ name: this.state.name, address: this.state.address },
				{ query: {} }
			)
			.then(result => {
				this.props.hide();
			})
			.catch(error => {
				// add error handler
				console.log(error);
				this.props.hide();
			});
	};

	componentWillMount() {
		let url = window.localStorage.getItem("tmining-wallet-url");
		let token = window.localStorage.getItem("tmining-wallet-token");

		this.props.services.walletService
			.requestActivation(token, url)
			.then(rpc => {
				this.props.services.identityService
					.get(rpc.result.address)
					.then(result => {
console.log(result);						
						// already registered
						this.setState({
							name: result.name,
							readOnly: result.id !== 0,
							address: rpc.result.address
						});
					})
					.catch(error => {
						this.setState({ address: rpc.result.address });
						console.log(error);
					});
			})
			.catch(error => {
				console.log(error);
			});
	}

	render() {
		return (
			<Modal isOpen={this.props.show} className="modal-width2">
				<ModalHeader>{i18next.t("title.identityform")}</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit} ref={el => this.form = el}>
						<FormGroup row>
							<Label for="address" sm={2} className="text-right">
								{i18next.t("form.identity.address")}
							</Label>
							<Col sm={10}>
								{1 == 0 && (
									<Input
										id="address"
										type="text"
										placeholder="Enter address"
										value={this.state.address}
										required={true}
										onChange={e =>
											this.setState({
												address: e.target.value
											})
										}
									/>
								)}
								<Input
									id="address"
									type="text"
									placeholder="Enter address"
									value={this.state.address}
									required={true}
									readOnly={true}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label for="name" sm={2} className="text-right">
								{i18next.t("form.identity.name")}
							</Label>
							<Col sm={10}>
								<Input
									id="name"
									className=""
									type="text"
									placeholder="Enter name"
									value={this.state.name}
									required={true}
									readOnly={this.state.readOnly}
									onChange={e =>
										this.setState({
											name: e.target.value
										})
									}
								/>
							</Col>
						</FormGroup>

						<ButtonToolbar className="float-right">
							{!this.state.readOnly && (
								<Button
									className="btn-default"
									type="button"
									onClick={() => {ReactDOM.findDOMNode(this.form).dispatchEvent(new Event("submit"));}}
								>
									<FontAwesome
										name="check"
										className="text-success"
									/>
									<Label className="mb-auto align-middle ml-2">
										{i18next.t("button.accept")}
									</Label>
								</Button>
							)}
							<Button
								className="btn-default"
								onClick={this.props.hide}
								type="button"
							>
								<FontAwesome
									name="times"
									className="text-danger"
								/>
								<Label className="mb-auto align-middle ml-2">
									{i18next.t("button.cancel")}
								</Label>
							</Button>
						</ButtonToolbar>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

export default IdentityForm;
