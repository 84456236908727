import React from "react";
import ReactDOM from "react-dom";
//import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./tmining.css";
import "./tmining2.css";
import i18next from "i18next";
import XHR from "i18next-xhr-backend";
import Cache from "i18next-localstorage-cache";
import LanguageDetector from "i18next-browser-languagedetector";
import sprintf from "i18next-sprintf-postprocessor";
import io from "socket.io-client";
import feathers from "@feathersjs/client";
//import Raven from "raven-js";
import moment from "moment";
import config from "./config.json";

import socketio from '@feathersjs/socketio-client';
//import hooks from 'feathers-hooks';
import auth from '@feathersjs/authentication-client';

import * as Sentry from '@sentry/browser';

Sentry.init({ dsn: 'https://0581efa5c3624ec7804d8891dfa8dbef@sentry.io/1509059' });


moment().format();

/*
Raven.config(
	"https://0581efa5c3624ec7804d8891dfa8dbef@sentry.io/1509059"
).install();
*/
//const appSocket = io("https://vps10.t-mining.be:3030");
const appSocket = io(config.API_URL);
const app = feathers()
	//.configure(feathers.hooks())
	.configure(socketio(appSocket))
	// Use localStorage to store our login token
	.configure(
		auth({
			storage: window.localStorage
		})
	);

const services = {};

console.log("ALJHSKAHGDJKSDGSJGFSJDGSDJKGSDKJG");
console.log(window);
console.log(window.TMiningGovernanceAPI);
//console.log(window.TMiningWallet.API);

// initialize the various services as globals
services.organisationService = app.service("organizations");
services.bondService = app.service("bond");
services.bondVerificationService = app.service("bond_verification");
services.userService = app.service("users");
services.identityService = app.service("identities");

services.walletService = window.TMiningGovernanceAPI;
console.log(services.walletService);

services.organisationService.timeout = 60000;
services.bondService.timeout = 60000;
services.userService.timeout = 60000;
services.bondVerificationService.timeout = 60000
services.identityService.timeout = 60000;

class Logger {
	constructor() {
		// bind methods
		this.log = this.log.bind(this);

		this.debug = this.debug.bind(this);
		this.info = this.info.bind(this);
		this.warn = this.warn.bind(this);
		this.error = this.error.bind(this);
		this.fatal = this.fatal.bind(this);

		this.loglevels = {
			1: "DEBUG",
			2: "INFO",
			3: "WARN",
			4: "ERROR",
			5: "FATAL"
		};
		this.loglevel = 1;
	}

	debug(location, message, addendum) {
		this.log(1, location, message, addendum);
	}

	info(location, message, addendum) {
		this.log(2, location, message, addendum);
	}

	warn(location, message, addendum) {
		this.log(3, location, message, addendum);
	}

	error(location, message, addendum) {
		this.log(4, location, message, addendum);
	}

	fatal(location, message, addendum) {
		this.log(5, location, message, addendum);
	}

	/**
	 * Log a message
	 */
	log(loglevel, location, log, addendum) {
		// trace all levels equal or greather then the set loglevel
		if (loglevel >= this.loglevel) {
			let user = app.get("user");
			if (!user) {
				user = { name: "unauthenticated" };
			}

			let message = `${JSON.stringify(log)} ${
				addendum ? "" + addendum : ""
			}`;

			let timestamp = moment()
				.utc()
				.format();

			// only log ERROR or FATAL messages
			if (loglevel >= 4) {
				// in case of a HTTP error we can check whether we get an actual error in the response
				if (log.response && log.response.text) {
					let errorMessage = JSON.parse(log.response.text);
					log.message = errorMessage.error;
					message = JSON.stringify(errorMessage);
				}

				Sentry.captureMessage(
					`${timestamp} ${this.loglevels[loglevel]} <${
						user.name
					}> ${location} - ${log.toString()}`
				);
			}

			if (location) {
				console.log(
					`${timestamp} ${this.loglevels[loglevel]} <${
						user.name
					}> ${location} - ${message}`
				);
			} else {
				console.log(
					`${timestamp} ${this.loglevels[loglevel]} <${
						user.name
					}> ${message}`
				);
			}
		}
	}
}

const logger = new Logger();

i18next
	.use(XHR) // or any other backend implementation
	.use(Cache) // or any other cache implementation
	.use(LanguageDetector) // or any other implementation
	.use(sprintf) // or any other post processor
	.init(
		{
			debug: true,
			fallbackLng: "en",
			backend: {
				loadPath: "locales/{{lng}}/translation.json"
			}
		},
		(error, t) => {
			// set the moment locale
			moment.locale(i18next.language);

			ReactDOM.render(
				<App app={app} services={services} logger={logger} />,
				document.getElementById("root")
			);

			// If you want your app to work offline and load faster, you can change
			// unregister() to register() below. Note this comes with some pitfalls.
			// Learn more about service workers: http://bit.ly/CRA-PWA
			serviceWorker.unregister();
		}
	);
