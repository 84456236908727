import React, { Component } from "react";
import {
	Form,
	FormGroup,
	Label,
	Input,
	Col,
	Row,
	Button,
	ButtonToolbar
} from "reactstrap";
import i18next from "i18next";

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: "",
			password: "",
      users: []
		};
    this.env = process.env.NODE_ENV;
	}

	handleSubmit = event => {
		event.preventDefault();

		this.props.authenticate({
			strategy: "local",
			userName: this.state.user,
			password: this.state.password
		});
	};

	async componentDidMount() {
    let users = await this.props.services.userService.find({ query: { type: 'getusers' } })

    this.setState({ 
      users: users.map(u => { 
        return { label: u.userName, value: u.id }
      }) 
    })
  }

	render() {
		return (
			<Row>
				<Col className="center-form">
					<Form onSubmit={this.handleSubmit}>
						<FormGroup row>
							<Label for="username" sm={2} className="text-right">
								{i18next.t("form.login.username")}
							</Label>
							<Col sm={10}>
								{ 
                 this.env === "development" 
                 ? (<Input type="select" value={this.state.user} onChange={e => this.setState({ user: e.target.value })}>
                     <option disabled value="">Select user...</option>
                      { this.state.users.map(u => (<option key={u.label} value={u.label}>{u.label}</option>)) }
                    </Input>)
                 : (<Input id="username" type="text" placeholder="Enter username"
													 value={this.state.user} required={true} onChange={e => this.setState({ user: e.target.value })}
										/>)
               }
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label for="password" sm={2} className="text-right">
								{i18next.t("form.login.password")}
							</Label>
							<Col sm={10}>
								<Input
									id="password"
									className=""
									type="password"
									placeholder="Enter password"
									value={this.state.password}
									required={true}
									onChange={e =>
										this.setState({
											password: e.target.value
										})
									}
								/>
							</Col>
						</FormGroup>

						<ButtonToolbar className="float-right">
							<Button
								className="btn-default"
								type="submit"
							>
								{i18next.t("button.login")}
							</Button>
						</ButtonToolbar>
					</Form>
				</Col>
			</Row>
		);
	}
}

export default Login;
