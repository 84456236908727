import React, { Component } from "react";
import { Navbar, NavbarBrand, Nav, NavItem, Button, Label, Input } from "reactstrap";
import FontAwesome from "react-fontawesome";
//import logo from "./logo.svg";
//import "./App.css";
import logo from "./credendo.png";
import Login from "./components/Login.jsx";
import BondList from "./components/BondList.jsx";
import BondDetailsPage from "./components/BondDetailsPage.jsx";
import WalletConfig from "./components/WalletConfig.jsx";
import alasql from "alasql";
import config from "./config.json";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      db_version: 6,
      authenticated: false,
      fetched: true,
      bondReference: "",
      bond: null,
      renderLogin: false,
      walletConnect: false,
      showWalletConfig: false,
      address: "",
      password: "",
      unlocked: false,
      error: "",
      pincodeFetched: false,
      pincode: "",
      url: "http://localhost:3002"
    };
  }


  testPincode = () => {

(async() => {

// make sure e have some data
this.props.services.walletService._storeWalletInfo({});

// unlock the wallet so we can send stuff

console.log(window.TMiningWallet);


try {
console.log("unlocking");

let token = await window.TMiningWallet.API.unlockAccount({password: this.state.password}, this.state.url);

console.log("unlocked");


let info = window.TMiningWallet.API._getWalletInfo();

console.log(info);

this.setState({token: info.token, unlocked: true});
} catch (error) {
console.log(error);
  this.setState({unlocked: false, error: error.message});
}

try {
// get the pincode
let pincode = await window.TMiningWallet.API.getPincode(this.state.address, this.state.url);

this.setState({pincode: pincode, pincodeFetched: true});
} catch (error) {
console.log(error);
  this.setState({pincodeFetched: false, error: error.message});
}


  })()
  .then(() => {})
  .catch(error => {
  this.setState({error: error});
  })

  }


  testChallenge = () => {


(async() => {
console.log("a");

this.props.services.walletService._storeWalletInfo({});

await window.TMiningGovernanceAPI.unlockAccount({password: "test"}, "https://vps1.t-mining.be:3000");


console.log("unlocked")


   let challenge =  await this.props.services.walletService.challenge( {"challenge": "tester",
    "signature": "518e3c184e57f5c826e5eb0d7894a04734c3176f508bbae362636c5464c6157a05c381d0d3270b9efa0b1b73afa680b4b0166e72ef48548ed7a681624d4e3339ce68531cb97ec5993c40345118665aa0392c2b811c24a66fe59da90163a44a31e95c009339acd487e69fd582d1a5ddfe5673a0791c634dc80695e1770fd8de21bd9d7c9021e44e7374215a43fdd34d367bb2c1f28a2546fbd6fd7da94c44f5ecfc06818450d89a9fff7d87211812344cc8cebe6e04f6458959bd90e072ce5dfa5ff3351552eea0a136edd0c485ee923331b44b3f5fc9f4c4103ef2ffc3b49dc97bea2fe0af572f01ec15ac1a8fd951b7422384751b4257032db1befe4cbd197a8718ca592ab3c99e814340a3537e3a1e034f7a2946e68e0dd551cc6a7957c6608521e785cdf60173c9105ab5cebaf8096fd6aa41a31cc316df71daf7eccf5c4f9d73c29fc28762230d390d5a3043c5843a9d7bdfba405a814d6377597b30c1ef5f2de63ab2aa40f81be1642fdd6ecfb2f1c70c6e3cac77328597e31829be0221254bbe9c180b976a2b4d8f6d3e1f40913b121030de5d3bc1cfd164b8e41b05925af689e48b1349e601ec142228a09cf8992cd6416cc444ad19fb6f6e90a90f05390357070d9e9d14e9e2a7ffe30e471814596c5c7f5d7dca6d4e2df027868a5c2d305d32f44a65d4d0699d93d98747d3a8d84ae388c309b94301a2de813b1fce"}, "https://vps1.t-mining.be:3000");

console.log(challenge);

challenge = await window.TMiningWallet.API.challenge( {"challenge": "somechallengestring",
    "signature": "somesignatureinhexformat"}, "https://vps1.t-mining.be:3000");


console.log(challenge);


let info = await this.props.services.walletService._getInfo("https://vps1.t-mining.be:3000");

console.log(info);

let pk = await this.props.services.walletService._getWalletPublicKey("https://vps1.t-mining.be:3000");

console.log(pk);

console.log("b");


let exportAccount = await this.props.services.walletService.exportAccount(pk, "https://vps1.t-mining.be:3000");


console.log(exportAccount);

//console.log(this.props.services.walletService.challenge.toString());
//console.log(this.props.services.walletService.getPincode.toString());

    let result= await this.props.services.walletService.getPincode("0x02648Ac494ddc36e98b7016383FB839C89c8D391", "https://vps1.t-mining.be:3000")

console.log(this.props.services.walletService._getWalletInfo());

console.log("c");
})()
.then(result => {
  console.log(result);
})
.catch(error => {
  console.log(error);
})

  }

  render() {

    return (
      <div className="App">
            <div>
               URL

                <Input
                  id="url"
                  type="text"
                  placeholder="Enter url"
                  value={this.state.url}
                  onChange={e =>
                    this.setState({
                      url: e.target.value
                    })
                  }
                />
            </div>

            <div>
                WalletPassword

                <Input
                  id="password"
                  type="text"
                  placeholder="Enter password"
                  value={this.state.password}
                  onChange={e =>
                    this.setState({
                      password: e.target.value
                    })
                  }
                />
            </div>

            <div>
                PickuprightAddress

                <Input
                  id="address"
                  type="text"
                  placeholder="Enter address"
                  value={this.state.address}
                  onChange={e =>
                    this.setState({
                      address: e.target.value
                    })
                  }
                />
            </div>

                  <Button className="btn" onClick={() => this.testPincode()}>
                      <FontAwesome
                        name="wallet"
                      />
                  </Button>

          <div>
          Unlocked: {`${this.state.unlocked}`}
          </div>

          <div>
          Token: {this.state.token}
          </div>

          <div>
          Pincode: {this.state.pincode}
          </div>


          <div>
          Error: {this.state.error}
          </div>



      </div>
    );
  }
}

export default App;
