import React, { Component } from "react";
import {
  Button,
  Label,
  UncontrolledTooltip
} from "reactstrap";
import BondActionButton from "./BondActionButton.jsx";
import DateTime from "./DateTime.jsx";
import FontAwesome from "react-fontawesome";
import { blobToBase64String } from "blob-util";

class Bond extends Component {

  handleError = (error) => {
    console.log(error);
  }

  uploadFile = event => {
    let id = this.props.bond.id;
    let uploadedFile = event.target.files[0];

    blobToBase64String(uploadedFile)
      .then(base64File => {
        let file = {name: uploadedFile.name, content: base64File};

        this.props.services.bondService
          .update(id, file, { query: { type: "setFile" } })
          .then(result => {

          })
          .catch(error => {
            this.handleError(error);
          });
      })
      .catch(error => {
        this.handleError(error);
      });
  };


  renderDownloadComponent = file => {
    let tooltip1 = `Download bond`;
    let tooltip2 = `Show bond`;

    let fileId = file === undefined ? 0 : parseInt(file, 16);

console.log(fileId);

    return (
      <td>
        <div className="d-flex">
          <div className="text-center">
            {this.props.QA && (
              <Label
                className="btn btn-link mb-auto"
                id={`UploadComponent_${this.props.bond.id}`}
              >
                <FontAwesome
                  name="file-upload"
                  size="2x"
                  className="text-info"
                />
                <input
                  className={"btn-link"}
                  type="file"
                  onChange={event => this.uploadFile(event)}
                  style={{ display: "none" }}
                  id="group_image"
                />
              </Label>
            )}

            {fileId !== 0 && (
              <Button
                id={`DownloadComponent_${this.props.bond.id}`}
                className={"btn-link"}
                onClick={() => {
                  this.props.download(this.props.bond.id);
                }}
              >
                <FontAwesome
                  name="file-download"
                  size="2x"
                  className="text-success"
                />
              </Button>
            )}
          </div>
          {fileId !== 0 && (
            <UncontrolledTooltip
              target={`DownloadComponent_${this.props.bond.id}`}
            >
              {tooltip1}
            </UncontrolledTooltip>
          )}
          <div className="text-center">
            {fileId !== 0 && (
              <Button
                id={`ShowComponent_${this.props.bond.id}`}
                className={"btn-link"}
                onClick={() => {
                  this.props.showBond(this.props.bond.id);
                }}
              >
                <FontAwesome name="eye" size="2x" className="text-success" />
              </Button>
            )}
          </div>
          {fileId !== 0 && (
            <UncontrolledTooltip target={`ShowComponent_${this.props.bond.id}`}>
              {tooltip2}
            </UncontrolledTooltip>
          )}
        </div>
      </td>
    );
  };

  render() {
    console.log(this.props.bond);
    return (
      <tr>
        <td>{this.props.bond.reference}</td>
        <td>{this.props.bond.creator ? this.props.bond.creatorName : ""}</td>
        <td>
          <DateTime dateTime={this.props.bond.creationTime} />
        </td>

        <td>{this.props.bond.owner ? this.props.bond.ownerName : ""}</td>

        {this.renderDownloadComponent(this.props.bond.file)}

        <td>
          <BondActionButton
            app={this.props.app}
            bond={this.props.bond}
            showDetails={this.props.showDetails}
            showHistory={this.props.showHistory}
          />
        </td>
      </tr>
    );
  }
}

export default Bond;
